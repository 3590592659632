<template>
  <div v-frag>
    <v-card :loading='loading' class='pb-5 ma-5 elevation-3 rounded-lg'>
      <v-row class='flex-column align-center'>
        <v-card-title class='mt-5 d-flex card-title justify-start justify-md-center headline'>
          Hi {{ employeeProfile.firstName }}<span class='d-none d-sm-block'> ,  {{ sections.overview_choices }}</span>
        </v-card-title>
        <template
          v-for='btn in filteredButtons'
        >
          <v-btn
            v-if="isShowButton(btn)"
            :key='btn.id'
            :color="btn.disabled ? '#3669B1' : 'primary'"
            class='my-2 mx-5 card-btn'
            :class="{'card-btn-disabled': (btn.disabled && btn.id == 1) }"
            height='40'
            link
            max-width='700px'
            min-width='240px'
            @click='onBtnClick(btn)'
          >
            {{ btn.text }}

            <template v-if='btn.disabled' class='custom-icon'>
              <svg class='custom-icon' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9_63)">
                  <rect width="16" height="16" rx="4" fill="white"/>
                  <path d="M12.6667 0H3.33334C1.49331 0.00221875 0.00221875 1.49331 0 3.33334V12.6667C0.00221875 14.5067 1.49331 15.9978 3.33334 16H12.6667C14.5067 15.9978 15.9978 14.5067 16 12.6667V3.33334C15.9978 1.49331 14.5067 0.00221875 12.6667 0ZM13.3333 5.386L7.164 11.5553C6.64341 12.0762 5.79919 12.0763 5.27838 11.5557C5.27825 11.5556 5.27812 11.5555 5.278 11.5553L2.66666 8.94469C2.40578 8.68381 2.40578 8.26088 2.66666 8.00003C2.92753 7.73919 3.35047 7.73916 3.61131 8.00003L6.22197 10.6107L12.392 4.44134C12.6528 4.18141 13.075 4.18216 13.335 4.443C13.5949 4.70387 13.5942 5.12606 13.3333 5.386Z" fill="#67AD5B"/>
                </g>
                <defs>
                  <clipPath id="clip0_9_63">
                    <rect width="16" height="16" rx="4" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </template>
            <v-icon v-if='btn.id === 1 && !btn.disabled' class='custom-icon' size='24'>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
      </v-row>
    </v-card>
    <custom-alert/>
    <verify-employee-registration-dialog v-if="isShowVoterRegistrationPopup" @close="closeVoteRegistrationPopup"/>
    <VerifyEmployeeRegistrationDialogStep5 v-if="isShowVerifyEmployeeRegistrationDialogStep5" :loading="false" @close="closeVerifyEmployeeRegistrationDialogStep5"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomAlert from './components/CustomAlert'
import VerifyEmployeeRegistrationDialog from '@/components/verify-employee-registration/VerifyEmployeeRegistrationDialog'
import VerifyEmployeeRegistrationDialogStep5 from "@/components/verify-employee-registration/VerifyEmployeeRegistrationDialogStep5.vue";
export default {
  name: 'EmployeeOverview',
  components: {VerifyEmployeeRegistrationDialog, CustomAlert, VerifyEmployeeRegistrationDialogStep5 },
  data: () => ({
    loading: true,
    isShowVoterRegistrationPopup: false,
    isShowVerifyEmployeeRegistrationDialogStep5: false,
  }),
  async created() {
    await this.getEmployeeId()
    await this.getEmployeeProfile()
    this.loading = false

    if (this.isNeedVoterRegistration && (!this.isShowedVoterRegistrationPopup || (!this.showedVoterRegistrationPopupAt || this.showedVoterRegistrationPopupAt < new Date().setHours(0, 0, 0, 0)))) {
      this.$store.state.employeeStore.isShowedVoterRegistrationPopup = true
      this.$store.state.employeeStore.showedVoterRegistrationPopupAt = new Date().setHours(0, 1, 0, 0)
      this.isShowVoterRegistrationPopup = true
    }
    
    if (this.isFirstLogin && !this.isShowedVoterRegistrationPopup && !this.isNeedVoterRegistration) {
      this.isShowVerifyEmployeeRegistrationDialogStep5 = true
    }
  },
  computed: {
    ...mapState({
      employeeProfile: state => state.employeeStore.employeeProfile,
      sections: state => state.cmsStore.sections,
      employeeId: state => state.employeeStore.employeeId,
      upcomingElections: state => state.employeeStore.upcomingElections,
      isNeedVoterRegistration: state => state.user.is_need_voter_registration,
      isShowedVoterRegistrationPopup: state => state.employeeStore.isShowedVoterRegistrationPopup,
      showedVoterRegistrationPopupAt: state => state.employeeStore.showedVoterRegistrationPopupAt,
      isFirstLogin: state => state.user.is_first_login,
    }),
    ...mapGetters(['user']),
    filteredButtons() {
      let btns = this.user.id_rol === 5 // 5 - Employee High Hierarchy, 4 - Employee
          ? this.buttons
          : this.buttons.filter(btn => btn.hierarchy !== 'high')
      btns = btns.filter(btn => btn.module ? this.employeeProfile.modules.includes(btn.module) : btn)
      return btns
    },
    buttons() {
      return [
        {
          id: 1,
          text: (!!this.employeeProfile.vuid || this.employeeProfile.scheduleVuid) ? 'You Are Registered to Vote!' : 'Register to vote' ,
          disabled: (!!this.employeeProfile.vuid || this.employeeProfile.scheduleVuid),
          module: 'voting'
        },
        {
          id: 2,
          text: this.employeeProfile.voterDay ? 'Your Scheduled Voting Day is ' + this.employeeProfile.voterDay :'Schedule a day to vote',
          disabled: !!this.employeeProfile.voterDay ,
          routeName: 'MyVoting',
          module: 'voting',
          submodule: 'schedule'
        },
        {
          id: 3,
          text: 'See my voter guide',
          routeName: 'MyVoting',
          hash: 'voterGuide',
          module: 'voting',
          submodule: 'voterGuide'
        },
        {
          id: 4,
          text: 'Volunteer',
          routeName: 'CharityInvolvementVolunteering',
          module: 'volunteering'
        },
        {
          id: 5,
          text: 'Donate to a charity',
          routeName: 'CharityInvolvementDonations',
          module: 'donations'
        },
        {
          id: 6,
          text: 'Manage my team',
          routeName: 'ManageMyTeam',
          hierarchy: 'high'
        },
        {
          id: 7,
          text: 'Send a communication',
          routeName: 'ManageMyTeam',
          hierarchy: 'high',
          hash: 'communications'
        }
        // {
        //   id: 7,
        //   text: 'I want to review my campaign checklist',
        //   routeName: 'ManageMyTeam',
        //   hash: 'campaign-checklist'
        // },
      ]
    }
  },
  methods: {
    ...mapActions('employeeStore', ['getEmployeeProfile', 'getEmployeeId', 'getElections']),
    async onBtnClick(btn) {
      if (btn.id === 1) {
        if (!btn.disabled) {
          window.open('https://vrapp.sos.state.tx.us/index.asp', '_blank')
        }
      } else if (btn.id === 3) {
        await this.getElections(this.employeeId)
        if (this.upcomingElections.length === 1) {
          this.$router.push({ name: 'VotersGuideEmployee', params: { employeeId: this.employeeId } })
          this.$store.dispatch('election', this.upcomingElections[0])
        } else {
          'hash' in btn
            ? this.$router.push({ name: btn.routeName, hash: `#${btn.hash}` })
            : this.$router.push({ name: btn.routeName })
        }
      } else {
        'hash' in btn
          ? this.$router.push({ name: btn.routeName, hash: `#${btn.hash}` })
          : this.$router.push({ name: btn.routeName })
      }
    },
    isShowButton(btn) {
      if (btn.submodule === 'voterGuide') {
        if (this.employeeProfile.isHiddenVoterGuide) {
          return false;
        }
        if (this.employeeProfile.voterGuidesByElection.length === 1) {
          let election = this.employeeProfile.voterGuidesByElection[0]
          return !(election.runoff && !election.voterGuide);
        } else {
          let hasVoterGuide = false
          this.employeeProfile.voterGuidesByElection.forEach(item => {
            if (item.runoff && !item.voterGuide && hasVoterGuide === false) {
              hasVoterGuide = false
            } else if (item.runoff && item.voterGuide) {
              hasVoterGuide = true
            } else if (!item.runoff) {
              hasVoterGuide = true
            }
          })
          return hasVoterGuide;
        }
      }
      if (btn.submodule === 'schedule') {
        if (this.employeeProfile.voterGuidesByElection.length === 1) {
          let election = this.employeeProfile.voterGuidesByElection[0]
          return !(election.runoff && !election.voterGuide);
        } else {
          let hasVoterGuide = false
          this.employeeProfile.voterGuidesByElection.forEach(item => {
            if (item.runoff && !item.voterGuide && hasVoterGuide === false) {
              hasVoterGuide = false
            } else if (item.runoff && item.voterGuide) {
              hasVoterGuide = true
            } else if (!item.runoff) {
              hasVoterGuide = true
            }
          })
          return hasVoterGuide;
        }
      }
      return true;
    },
    closeVoteRegistrationPopup() {
      this.isShowVoterRegistrationPopup = false
      this.$store.state.employeeStore.isShowedVoterRegistrationPopup = true
    },
    closeVerifyEmployeeRegistrationDialogStep5() {
      this.isShowVerifyEmployeeRegistrationDialogStep5 = false
      this.$store.state.employeeStore.isShowedVoterRegistrationPopup = true
    }
  },
}
</script>
<style lang='scss' scoped>
.custom-icon {
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.card-btn {
  width: 90%;
  @media (max-width: 600px) {
    width: 80%;
  }
  color: white;
  &-disabled:hover {
    cursor: auto;
    background-color: #3669B1 !important;
  }
}
.card-title {
  @media (max-width: 600px) {
    width: 95%;
  }
}
</style>

