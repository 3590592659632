<template>
  <div>
    <VerifyEmployeeRegistrationDialogStep1
      v-if="step === 1"
      :loading="loading"
      @goToStep2="goToStep2"
      @goToStep3="goToStep3"
    />
    <VerifyEmployeeRegistrationDialogStep2
      v-if="step === 2"
      :loading="loading"
      @goToStep3="goToStep4"
      @refillAddress="refillAddress"
    />
    <VerifyEmployeeRegistrationDialogRescreenStep
      v-if="step === 3"
      :loading="loading"
      :employeeExceptions="employeeExceptions"
      @acceptRescreen="acceptRescreen"
      @goToStep4="goToStep4"
    />
    <VerifyEmployeeRegistrationDialogStep4
      v-if="step === 4"
      :loading="loading"
      @changeERSStatus="changeERSStatus"
      @remindLater="remindLater"
    />
    <VerifyEmployeeRegistrationDialogStep5
      v-if="step === 5"
      :loading="loading"
      @close="remindLater"
    />
  </div>
</template>

<script>
import VerifyEmployeeRegistrationDialogStep1
  from "@/components/verify-employee-registration/VerifyEmployeeRegistrationDialogStep1.vue";
import VerifyEmployeeRegistrationDialogStep2
  from "@/components/verify-employee-registration/VerifyEmployeeRegistrationDialogStep2.vue";
import VerifyEmployeeRegistrationDialogRescreenStep
  from "@/components/verify-employee-registration/VerifyEmployeeRegistrationDialogRescreenStep.vue";
import VerifyEmployeeRegistrationDialogStep4
  from "@/components/verify-employee-registration/VerifyEmployeeRegistrationDialogStep4.vue";
import VerifyEmployeeRegistrationDialogStep5
  from "@/components/verify-employee-registration/VerifyEmployeeRegistrationDialogStep5.vue";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "VerifyEmployeeRegistrationDialog",
  components: {
    VerifyEmployeeRegistrationDialogRescreenStep,
    VerifyEmployeeRegistrationDialogStep1,
    VerifyEmployeeRegistrationDialogStep2,
    VerifyEmployeeRegistrationDialogStep4,
    VerifyEmployeeRegistrationDialogStep5,
  },
  data: () => ({
    employeeId: '',
    employeeEmail: '',
    step: 1,
    employeeExceptions: [],
    loading: false,
  }),
  methods: {
    ...mapActions('employeeStore', ['rescreenOnRegistration', "applyRescreenOnRegistration", "applyVoterAddress", "changeERSTabStatus"]),
    ...mapMutations('employeeStore', ['SET_CUSTOM_ALERT_DATA']),
    async goToStep2() {
      this.step = 2
    },
    async goToStep4() {
      this.step = 4
    },

    async goToStep3() {
      this.SET_CUSTOM_ALERT_DATA({
        show: true,
        color: 'info',
        message: 'Searching the voter registration database. Please stand by.'
      })
      this.loading = true

      const res = await this.rescreenOnRegistration()
      if (res.status) {
        this.employeeExceptions = res.exceptionsTo
        this.step = 3
      } else {
        this.step = 4
      }

      this.loading = false
    },

    async refillAddress() {
      this.loading = true
      await this.applyVoterAddress()
      this.loading = false
      this.step = 5
    },
    async acceptRescreen(items) {
      this.loading = true
      await this.applyRescreenOnRegistration({selectedId: items[0].id})
      this.loading = false
      this.$emit('close')
    },
    async changeERSStatus() {
      await this.changeERSTabStatus()
      this.$emit('close')
    },
    remindLater() {
      this.$emit('close')
    }
  },
}
</script>

<style scoped>

.card-header-title {
  font-size: 24px;
  font-weight: bold;
}

.card-body-text {
  font-size: 22px;
  font-weight: normal;
  color: #141414;
}


</style>
