<template>
  <v-dialog
    v-model='isOpen'
    persistent
    max-width='1070px'
    transition='dialog-bottom-transition'
  >
    <v-card class="px-6" :loading="loading">
      <div class="py-10">
        <v-card-title class='card-header-title pa-0 mb-6'>
          Possible Matches
        </v-card-title>
        <v-card-text class="d-flex flex-column pa-0 my-8">
          <span class="card-body-text-header">Have you ever lived at one of these addresses?</span>
          <span class="card-body-text">Please select the line below that matches a current or previous address and your birth year.</span>
        </v-card-text>
        <v-data-table
          class='mb-5'
          :headers='headers'
          hide-default-footer
          :items='exceptionsTo'
          single-select
          selectable-key='id'
          show-select
          v-model='picked'
        >
        </v-data-table>
        <v-row class='d-flex align-center justify-end'>
          <v-btn @click='goToStep4' color='primary' elevation='0' class='mx-2'>Not me</v-btn>
          <v-btn
            color='primary'
            @click='update'
            :loading='updateLoading'
            :disabled='picked.length === 0'
            elevation='0'
          >Confirm
          </v-btn>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerifyEmployeeRegistrationDialogRescreenStep",
  data: () => ({
    isOpen: false,
    employeeId: '',
    employeeEmail: '',
    picked: [],
    updateLoading: false,
    exceptionsTo: [],
    headers: [
      {text: 'First Name', value: 'firstName'},
      {text: 'Last Name', value: 'lastName'},
      {text: 'Address', value: 'voterAddress'},
      {text: 'City', value: 'voterCity'},
      {text: 'Zip', value: 'voterZip'},
      {text: 'Birth Year', value: 'birthDay'}
    ],
  }),
  props: {
    employeeExceptions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    goToStep4() {
      this.$emit('goToStep4')
    },
    update() {
      this.$emit('acceptRescreen', this.picked)
    }
  },
  mounted() {
    this.isOpen = true
    this.exceptionsTo = this.employeeExceptions
  }
}
</script>

<style scoped>
.card-header-title {
  font-size: 20px;
  font-weight: bold;
  color: #141414;
}

.card-body-text-header {
  font-size: 24px;
  font-weight: bold;
  color: #141414;
}

.card-body-text {
  font-size: 14px;
  font-weight: normal;
  color: #667085;
}


</style>