<template>
  <v-dialog v-model='isOpen' max-width='594' width='100%' persistent>
    <v-card class="px-6" :loading="loading">
      <div class="py-10" >
        <div class="d-flex justify-center mb-4 "  style="height: 80px">
          <LottieAnimation
            v-if="!showTextAfterGif"
            :animation-data="checkmark"
            :auto-play="true"
            :loop="false"
            :speed="1"
            ref="anim"
            @complete="onAnimationComplete"
            width="80px"
          />
          <div class="align-center d-flex">
            <v-fade-transition leave-absolute>
              <span v-if="showTextAfterGif" class='card-header-title'>Your voter registration is confirmed.</span>
            </v-fade-transition>
          </div>
        </div>
        <v-card-actions class="d-flex justify-center pa-0">
          <v-btn
            color="primary"
            outlined
            @click='close'
            width="50%"
            :disabled="loading"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-web-vue"
import checkmark from '@/assets/animation/check2.json'

export default {
  name: "VerifyEmployeeRegistrationDialogStep2",
  data: () => ({
    isOpen: false,
    employeeId: '',
    employeeEmail: '',
    showTextAfterGif: false,
    checkmark: checkmark,
  }),
  components: {
    LottieAnimation
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    employeeFullAddress() {
      return this.$store.state.employeeStore.employeeProfile.fullAddress2
    },
    hasFullAddress() {
      return this.$store.state.employeeStore.employeeProfile.fullAddress2.replaceAll(',', '').replaceAll(' ', '') !== ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async onAnimationComplete() {
      await this.$nextTick()
      this.showTextAfterGif = true
    }
  },
  mounted() {
    this.isOpen = true
    
  }
}
</script>

<style scoped>

.card-header-title {
  font-size: 22px;
  font-weight: normal;
  color: #141414;
}

.card-body-text {
  font-size: 22px;
  font-weight: normal;
  color: #141414;
}

</style>