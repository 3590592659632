<template>
  <v-dialog v-model='isOpen' max-width='594' width='100%' persistent>
    <v-card class="px-6" :loading="loading">
      <div class="py-10">
        <v-card-title class="justify-center d-flex flex-column pa-0 mb-8">
          <span class='card-header-title'>Hello! Let's verify your voter registration.</span>
          <span class='card-header-title' v-if="hasFullAddress">Have you ever lived at this address?</span>
        </v-card-title>
        <v-card-text class="justify-center d-flex mb-8" v-if="hasFullAddress">
          <span class="card-body-text">{{ employeeFullAddress }}</span>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between pa-0" v-if="hasFullAddress">
          <v-btn
            color="primary"
            outlined
            @click='goToStep3'
            width="50%"
            :disabled="loading"
          >
            No
          </v-btn>
          <v-btn
            color='success'
            @click='goToStep2'
            width="50%"
            :disabled="loading"
          >
            Yes
          </v-btn>
        </v-card-actions>
        <v-card-actions class="d-flex justify-center pa-0" v-else>
          <v-btn
            color="primary"
            outlined
            @click='goToStep3'
            width="50%"
            :disabled="loading"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerifyEmployeeRegistrationDialogStep2",
  data: () => ({
    isOpen: false,
    employeeId: '',
    employeeEmail: ''
  }),
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    employeeFullAddress() {
      return this.$store.state.employeeStore.employeeProfile.fullAddress2
    },
    hasFullAddress() {
      return this.$store.state.employeeStore.employeeProfile.fullAddress2.replaceAll(',', '').replaceAll(' ', '') !== ''
    }
  },
  methods: {
    goToStep3() {
      this.$emit('goToStep3')
    },
    goToStep2() {
      this.$emit('goToStep2')
    },
  },
  mounted() {
    this.isOpen = true
  }
}
</script>

<style scoped>

.card-header-title {
  font-size: 24px;
  font-weight: bold;
}

.card-body-text {
  font-size: 22px;
  font-weight: normal;
  color: #141414;
}


</style>
